import React from 'react';
import { usePrefs, useLabels } from 'wsm-common-data';
import Icon from './Icon';

const SubHeading = () => {
	const {
		widgetSubHeading,
		widgetSubHeadingClasses,
		widgetSubHeadingColor,
		widgetSubHeadingIcon
	} = usePrefs();

	const labels = useLabels();

	const icon = widgetSubHeadingIcon ? (
		<Icon name={widgetSubHeadingIcon} />
	) : null;

	const label = labels.get(widgetSubHeading);

	const classes = `sub-heading ${widgetSubHeadingClasses} ${
		widgetSubHeadingColor !== 'BLANK' ? widgetSubHeadingColor : ''
	}`;

	// Hide component when label is not available
	if (!label) {
		return null;
	}

	return (
		<p className={classes}>
			{icon}
			{label}
		</p>
	);
};

SubHeading.propTypes = {};

export default SubHeading;
