import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { optionType, valueType } from './type';
import Option from './Option';
import isSelected from '../../utilities/isSelected';
import { useSelectWithContext } from '../../hooks/useSelectWithContext';

const Options = ({ options, renderBottom, facetId }) => {
	const { getMenuProps, selectedItem, highlightedIndex, isOpen } =
		useSelectWithContext();

	return (
		<div className={`select-options ${!isOpen && 'hidden'}`}>
			<ul className="options" {...getMenuProps()}>
				{isOpen &&
					options.map((o, idx) => {
						return (
							<Option
								key={o.value}
								facetId={facetId}
								selected={isSelected(o, selectedItem)}
								highlighted={highlightedIndex === idx}
								option={o}
								index={idx}
							/>
						);
					})}
				{renderBottom && (
					<li role="menuitem" key="bottom-render">
						{renderBottom()}
					</li>
				)}
			</ul>
		</div>
	);
};

Options.propTypes = {
	facetId: PropTypes.string,
	options: PropTypes.arrayOf(optionType).isRequired,
	optionProps: PropTypes.shape({
		tabIndex: PropTypes.string.isRequired,
		onMouseDown: PropTypes.func.isRequired
	}).isRequired,
	snapshot: PropTypes.shape({
		highlighted: PropTypes.number.isRequired,
		value: valueType,
		option: optionType
	}).isRequired,
	renderBottom: PropTypes.func
};

export default memo(Options);
