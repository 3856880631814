import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { usePrefs, useFlags } from 'wsm-common-data';
import { createFetchAction } from '../actions/fetchFacetAction';
import {
	setFilterMain,
	setFilterModal,
	removeAllFiltersModal
} from '../actions/appliedFilterAction';
import { MAIN } from '../constant';

const useFetchFacets = (filters, triggerSource = MAIN, isRemoveAll = false) => {
	const requestData = useSelector((state) => state.widgetData);
	const prefs = usePrefs();
	const flags = useFlags();
	const dispatch = useDispatch();

	useEffect(() => {
		if (filters === null) {
			return;
		}
		const payLoad = {
			requestData,
			prefs,
			flags,
			triggerSource,
			action:
				triggerSource === MAIN
					? setFilterMain(filters)
					: setFilterModal(filters)
		};

		if (isRemoveAll) {
			payLoad.action = removeAllFiltersModal(filters);
		}
		dispatch(createFetchAction(payLoad));
	}, [filters, isRemoveAll]);
};

export default useFetchFacets;
