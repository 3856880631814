import { SET_APPLIED_FILTER_MAIN } from '../actions/types';

const initialState = {};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case SET_APPLIED_FILTER_MAIN:
			return { ...state, ...payload };

		default:
			return state;
	}
};
