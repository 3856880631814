import { SET_FACET_DATA_MODAL } from '../actions/types';

const initialState = {};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case SET_FACET_DATA_MODAL:
			return { ...state, ...payload };
		default:
			return state;
	}
};
