import { REMOVE_FACET_FILTERS_MODAL, SET_APPLIED_FILTER_MAIN, SET_APPLIED_FILTER_MODAL } from './types';

export const setFilterMain = (payload) => {
	return {
		type: SET_APPLIED_FILTER_MAIN,
		payload
	};
};

export const setFilterModal = (payload) => {
	return {
		type: SET_APPLIED_FILTER_MODAL,
		payload
	};
};

export const removeAllFiltersModal = (payload) => {
	return {
		type: REMOVE_FACET_FILTERS_MODAL,
		payload
	};
};
