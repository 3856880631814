export const convertFilter = (filters) => {
	const keys = Object.keys(filters);
	const selectFilters = keys.reduce((aggr, currentKey) => {
		const values = filters[currentKey].map((val) => {
			return {
				label: val,
				value: val
			};
		});
		return [...aggr, { id: currentKey, values }];
	}, []);
	return selectFilters;
};
