import {
	REMOVE_FACET_FILTERS_MODAL,
	SET_APPLIED_FILTER_MODAL
} from '../actions/types';

const initialState = {};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case SET_APPLIED_FILTER_MODAL:
			return { ...state, ...payload };

		case REMOVE_FACET_FILTERS_MODAL:
			return payload;

		default:
			return state;
	}
};
