import { SET_A11Y_STATUS } from '../actions/types';

const initialState = {};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case SET_A11Y_STATUS:
			return { message: payload };
		default:
			return state;
	}
};
