import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useRequestData, usePrefs } from 'wsm-common-data';
import FilterModal from './Modal/FilterModal';
import DropDownSelected from './DropDownSelected';
import { HTTP_STATUS } from '../reducers/httpReducer';
import { g } from '../global-constants';
import { getHttp } from '../utilities/selector';
import './style.scss';
import SubHeading from './SubHeading';

const Component = () => {
	const { deviceType, windowId } = useRequestData();
	const isDesktop = deviceType === g.DESKTOP;
	const [openModal, setOpenModal] = useState(false);
	const { status } = useSelector(getHttp);
	const prefs = usePrefs();
	const { borderRadiusOverride, backgroundContainer } = prefs;
	const containerRef = useRef(null);

	const widgetClassName = isDesktop ? 'desktop' : 'mobile mx-4';

	if (status === HTTP_STATUS.FAIL) {
		const errorText =
			'ws-facet-browse: Error attempting to retrieve facet data';
		const error = new Error(errorText);
		throw error;
	}

	const handleBorderRadius = () => {
		switch (borderRadiusOverride) {
			case 'inherit':
				return '';
			default:
				return borderRadiusOverride;
		}
	};

	return (
		<div>
			<SubHeading />
			<div
				className={`facet-browse-container ${widgetClassName} ${handleBorderRadius()} ${
					status === HTTP_STATUS.REQUESTING && !openModal
						? 'data-loading-form'
						: ''
				} ${backgroundContainer === 'bg-input' ? 'bg-alpha-none' : ''}
				`}
				ref={containerRef}
			>
				<div className="facet-browse-background" />
				<div className="sr-only" id={`${windowId}-a11y-status`} />
				<DropDownSelected
					isDesktop={isDesktop}
					setOpenModal={setOpenModal}
				/>
				<FilterModal
					isDesktop={isDesktop}
					openModal={openModal}
					setOpenModal={setOpenModal}
					containerRef={containerRef.current}
				/>
			</div>
			<div
				data-location="facet-browse"
				className="ddc-content web-api-added-placeholder text-center"
			/>
		</div>
	);
};

export default Component;
