import React, { useState, useLayoutEffect, useMemo, useRef } from 'react';
import { isBrowser } from 'ws-scripts/modules/environment';
import { useSelector, useDispatch } from 'react-redux';
import { usePrefs, useFlags, useLabels, useRequestData } from 'wsm-common-data';
import PropTypes from 'prop-types';
import { trackEvent } from 'ddc-track-event';
import filterFacetDisplay from '../utilities/filterFacetDisplay';
import SearchButton from './buttons/SearchButton';
import FacetSelect from './FacetSelect/FacetSelect';
import useFetchFacets from '../hooks/useFetchFacets';
import { createFetchAction } from '../actions/fetchFacetAction';
import Loading from './common/Loading';
import ControllerContainer from './common/ControllerContainer';
import { setFilterModal } from '../actions/appliedFilterAction';
import useWindowSize from '../hooks/useWindowSize';
import {
	getFacetDataMain,
	getFilterMain,
	getWidgetData
} from '../utilities/selector';
import { MODAL, layout } from '../constant';
import { SelectProvider } from '../hooks/useSelectWithContext';
import { dropdownSelectedTracking } from '../utilities/trackingFactory';

function itemToString(item) {
	return item ? item.label : '';
}

const DropDownSelected = ({ isDesktop, setOpenModal }) => {
	const facetData = useSelector(getFacetDataMain);
	const requestData = useSelector(getWidgetData);
	const inventoryParameters = useSelector(getFilterMain);
	const { windowId, widgetName } = useRequestData();

	const [selectFilter, setSelectFilter] = useState(null);
	const prefs = usePrefs();
	const { formLayout, numberOfDisplayFacets = 0, showAllFilters } = prefs;
	const showAllFiltersBool = showAllFilters === 'true';
	const flags = useFlags();
	const labels = useLabels();
	const dispatch = useDispatch();
	const isMobile = !isDesktop;
	const facetsContainerRef = useRef(null);
	const selectBoxClassess = isDesktop
		? 'desktop-select-box'
		: 'mobile-select-box';

	useFetchFacets(selectFilter);

	const getLayoutClasses = () => {
		// Always render two column layout in mobile
		if (isMobile) {
			return layout.TWO_COLUMN;
		}

		return formLayout;
	};

	const layoutClass = getLayoutClasses();

	const isInlineOrRowLayout =
		layoutClass === layout.INLINE || layoutClass === layout.ROW;

	const isDefaultLayout = layoutClass === layout.DEFAULT;

	const getCountFilterLabel = () => {
		const filterLabel = labels.get('ALL_FILTERS');
		return <span>{filterLabel}</span>;
	};

	const handleChange = (item, facetId) => {
		const { selectedItem: { value } = {} } = item;
		if (value === 'ALL') {
			setSelectFilter({ [facetId]: [] });
		} else {
			setSelectFilter({ [facetId]: [value] });
		}

		dropdownSelectedTracking(widgetName, windowId, facetId, value);
	};

	const handleOpenModal = () => {
		trackEvent(widgetName, windowId, {
			element: 'All filters button',
			result: 'User presented with ws-facet-browse-modal'
		});

		dispatch(setFilterModal(inventoryParameters));
		dispatch(
			createFetchAction({
				requestData,
				prefs: {
					...prefs,
					facetInstanceId: prefs.facetInstanceIdAllFilters
				},
				triggerSource: MODAL,
				flags
			})
		);

		// add overflow hidden for body element for disable scrolling
		const bodyContainer = document.body;
		bodyContainer.classList.add('overflow-hidden');
		setOpenModal(true);
	};

	const onVisibleChange = (isSelectOpen) => {
		if (isMobile && isBrowser && facetsContainerRef.current) {
			const facetContanerClasses = document.querySelector(
				'.facet-browse-container'
			).classList;
			if (isSelectOpen) {
				facetContanerClasses.add('mobile-hover-bg');
			} else {
				facetContanerClasses.remove('mobile-hover-bg');
			}
		}
	};

	const windowSize = useWindowSize();

	const calCulateFacetMetadata = (facetList) => {
		if (
			facetData.length === 0 ||
			!isInlineOrRowLayout ||
			!isBrowser ||
			!facetList
		) {
			return null;
		}
		const facetsContainer = facetList;

		// get facetMetadata from facetData
		const childrenEl = facetsContainer.children || [];
		const firstElPos = childrenEl[0]?.getBoundingClientRect();
		const firstElBottom = firstElPos.bottom;
		const childrenLength = childrenEl.length;
		const lastEl = childrenEl[childrenLength - 1];
		const lastElTopPosition = lastEl?.getBoundingClientRect().top;

		const FacetChildrenMetadata = Array.from(childrenEl).map(
			(currentEl) => {
				return {
					currentEl,
					prevEl: currentEl?.previousElementSibling,
					nextEl: currentEl?.nextElementSibling,
					currentElRect: currentEl.getBoundingClientRect() || {},
					prevRect:
						currentEl?.previousElementSibling?.getBoundingClientRect() ||
						{},
					nextRect:
						currentEl?.nextElementSibling?.getBoundingClientRect() ||
						{}
				};
			}
		);

		return {
			children: FacetChildrenMetadata,
			firstElBottom,
			lastElTopPosition
		};
	};

	const FacetMetadata = useMemo(
		() => calCulateFacetMetadata(facetsContainerRef.current),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[facetData, windowSize, calCulateFacetMetadata]
	);

	const { facets = [] } = facetData;
	const facetDropdowns = filterFacetDisplay(facets, numberOfDisplayFacets);
	const getFacetLayout = (facetIndex) => {
		const bottomBorderClasses = 'bottom-border pb-3 mb-3';
		// inline or a row
		if (isInlineOrRowLayout) {
			return 'right-border';
		}
		// one column
		if (isDefaultLayout) {
			return `col-xs-12 ${bottomBorderClasses}`;
		}
		// two column logic
		// account for !showAllFilters on mobile
		// first select on two column and number facet is even
		if (isMobile && !showAllFiltersBool) {
			if (facetDropdowns.length % 2 === 0 || facetIndex !== 0) {
				return `col-xs-6 ${bottomBorderClasses}`;
			} else {
				return `col-xs-12 ${bottomBorderClasses}`;
			}
		} else if (facetIndex === 0 && facetDropdowns.length % 2 === 0) {
			return `col-xs-12 ${bottomBorderClasses}`;
		}

		// when facetDropdowns is odd => add border right for even facetIndex 0,2,4,6
		// when facetDropdowns is even => add border right for even facetIndex 1,3,5,7
		// if facetIndex + facetDropdowns is odd => add border right
		const facetClassBorder =
			(facetDropdowns.length + facetIndex) % 2 === 1
				? 'right-border'
				: '';
		return `col-xs-6 ${bottomBorderClasses} ${facetClassBorder}`;
	};

	const scalingRow = ({ children, firstElBottom, lastElTopPosition }) => {
		let isDropped = false;
		if (lastElTopPosition >= firstElBottom) {
			isDropped = true;
		}
		// if no space left handle for drop element and add border for other element
		if (isDropped) {
			children.forEach(
				({
					currentEl,
					prevEl,
					nextEl,
					currentElRect,
					prevRect,
					nextRect
				}) => {
					// add border bottom and padding margin for non-drop el
					if (currentElRect.bottom <= nextRect.bottom) {
						currentEl.classList.add(
							'bottom-border',
							'pb-3',
							'mb-3',
							'right-border'
						);
						currentEl.classList.remove('left-border');
					} else {
						currentEl.classList.add('right-border');
					}
					//  Nomarl drop case
					if (currentElRect.top >= prevRect.bottom) {
						// remove right border for last element in first line
						prevEl?.classList.remove('right-border');
						// modify class for new drop el
						currentEl.classList.remove(
							'bottom-border',
							'pb-3',
							'mb-3',
							'right-border'
						);
						currentEl.classList.add('left-border');
					}
					//  In case last element
					if (!nextEl) {
						currentEl.classList.add('left-border');
						if (prevRect.bottom > currentElRect.top) {
							prevEl.classList.remove(
								'border-bottom',
								'pb-3',
								'mb-3',
								'right-border'
							);
							prevEl?.classList.add('left-border');
						} else {
							prevEl.classList.remove('right-border');
						}
					}
				}
			);
			// if enough space for all facets remove border class
		} else {
			children.forEach(({ currentEl }) => {
				currentEl.classList.remove(
					'bottom-border',
					'pb-3',
					'mb-3',
					'left-border'
				);
				if (!currentEl.classList.contains('right-border')) {
					currentEl.classList.add('right-border');
				}
			});
		}
	};

	useLayoutEffect(() => {
		if (!FacetMetadata) {
			return;
		}
		scalingRow(FacetMetadata);
	}, [FacetMetadata]);

	return (
		<>
			<Loading />
			<div
				className={`facet-dropdown-container d-flex justify-content-center align-items-center flex-wrap ${layoutClass}`}
				ref={facetsContainerRef}
			>
				{facetDropdowns.map((facetItem, facetIndex) => (
					<div
						className={`form-group ${getFacetLayout(facetIndex)}`}
						key={facetItem.id}
					>
						<SelectProvider
							items={facetItem.values}
							useSelectProps={{
								itemToString,
								// Disable default behavior of DownShift when selecting the item
								getA11ySelectionMessage: () => {},
								onSelectedItemChange: (item) =>
									handleChange(item, facetItem.id)
							}}
						>
							<FacetSelect
								facet={facetItem}
								isMobile={isMobile}
								onVisibleChange={onVisibleChange}
								inputClass={` btn font-weight-normal text-left ${
									!isInlineOrRowLayout && 'border-0 w-100'
								}`}
							/>
						</SelectProvider>
						<div className="facet-select-border-bottom" />
					</div>
				))}
				<ControllerContainer isInlineOrRowLayout={isInlineOrRowLayout}>
					{showAllFiltersBool && (
						<div
							className={`form-group all-filter ${
								isInlineOrRowLayout
									? 'right-border'
									: 'flex-grow-1 bottom-border pb-3 mb-3'
							}`}
						>
							<div className="facet-select ddc-bootstrap-select">
								<button
									type="button"
									className={`btn border-0 w-100 font-weight-normal all-filter-button text-left dropdown-toggle ${selectBoxClassess}`}
									onClick={handleOpenModal}
								>
									<i className="ddc-icon ddc-icon-filter-list-controls" />
									{getCountFilterLabel()}
								</button>
								<div className="facet-select-border-left" />
								<div className="facet-select-border-right" />
							</div>
							<div className="facet-select-border-bottom" />
						</div>
					)}
					<div
						className={`form-group disable-border ${
							isInlineOrRowLayout ? 'ml-4' : 'col-xs-12'
						}`}
					>
						<SearchButton
							facetFilters={inventoryParameters}
							className="btn w-100 btn-no-decoration"
							totalMatchFilter={facetData?.totalCount}
						/>
					</div>
				</ControllerContainer>
			</div>
		</>
	);
};

DropDownSelected.propTypes = {
	isDesktop: PropTypes.bool,
	setOpenModal: PropTypes.func
};

export default DropDownSelected;
