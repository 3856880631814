import React from 'react';
import PropTypes from 'prop-types';

const ControllerContainer = (props) => {
	const { isInlineOrRowLayout, children } = props;

	if (isInlineOrRowLayout) {
		return (
			<div className="d-flex facet-controller align-items-center">
				{children}
			</div>
		);
	}
	return <>{children}</>;
};

ControllerContainer.propTypes = {
	children: PropTypes.element.isRequired,
	isInlineOrRowLayout: PropTypes.bool
};

export default ControllerContainer;
