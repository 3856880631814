import convertToSnakeCase from './convertToSnakeCase';

/** Return Any label form id
 *
 * @param {*} labels
 * @param {*} id
 * @returns {string} Any label like 'Any','Any Price','Any Year'
 */
export default function getAnyLabel(labels = [], id = '', defaultlabel = '') {
	const alias = `ANY_${convertToSnakeCase(id)
		?.toUpperCase()
		// Remove GV,NORMAL prefix
		?.replace(/GV_|NORMAL_/g, '')}`;

	const label = labels[alias];

	return label || `${labels.ANY} ${defaultlabel}` || alias;
}
