import { HTTP_STATUS } from '../reducers/httpReducer';
import { SET_HTTP_STATUS } from './types';

export const httpDone = () => {
	return {
		type: SET_HTTP_STATUS,
		payload: { status: HTTP_STATUS.DONE }
	};
};

export const httpRequesting = () => {
	return {
		type: SET_HTTP_STATUS,
		payload: { status: HTTP_STATUS.REQUESTING }
	};
};

export const httpFailed = () => {
	return {
		type: SET_HTTP_STATUS,
		payload: { status: HTTP_STATUS.FAIL }
	};
};

export const httpSuccess = () => {
	return {
		type: SET_HTTP_STATUS,
		payload: { status: HTTP_STATUS.SUCCESS }
	};
};
