import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { setClassNames } from 'ddc-classnames-js';
import { usePrefs, useRequestData, useLabels } from 'wsm-common-data';

import useFormatNumber from '../../hooks/useFormatNumber';
import { optionType } from './type';
import Options from './Options';
import './facet_select.scss';
import { g } from '../../global-constants';
import { dropdownTracking } from '../../utilities/trackingFactory';
import { useSelectWithContext } from '../../hooks/useSelectWithContext';

const FacetSelect = ({
	facet = {},
	renderBottom,
	id,
	inputClass,
	onVisibleChange
}) => {
	const labels = useLabels();
	const { values: defaultOptions = [], id: facetId } = facet;

	const { deviceType, widgetName, windowId } = useRequestData();

	const isDesktop = deviceType === g.DESKTOP;
	const selectBoxClassess = isDesktop
		? 'desktop-select-box'
		: 'mobile-select-box';

	const { isOpen, selectedItem, getToggleButtonProps, getLabelProps } =
		useSelectWithContext();

	const { showCountOnSubmitButton } = usePrefs();
	const formatNumber = useFormatNumber();

	const wrapperClass = setClassNames(['facet-select', isOpen && 'has-focus']);

	useEffect(() => {
		// Handle change UI for mobiles
		onVisibleChange(isOpen);

		// Tracking open dropdown event
		if (isOpen) {
			dropdownTracking(widgetName, windowId, facetId);
		}
	}, [facetId, isOpen, onVisibleChange, widgetName, windowId]);

	return (
		<div
			className={`${wrapperClass} ddc-bootstrap-select`}
			id={id}
			data-cy-option-length={defaultOptions.length}
		>
			{/* Dont have any dropdown label in UI but need it to make DownShift happy and work properly */}
			<span className="hide" {...getLabelProps()} />
			<div className="value">
				<button
					type="button"
					{...getToggleButtonProps()}
					className={`btn ${inputClass} ${selectBoxClassess}`}
					aria-label={facet.label}
				>
					<span>
						<span>{selectedItem?.label}</span>
						<small className="ml-2 text-neutral-600">
							{showCountOnSubmitButton === 'false' &&
								selectedItem &&
								selectedItem.count && (
									<>
										{`${formatNumber(selectedItem.count)}`}
										<span className="sr-only">
											{selectedItem.count > 1
												? labels.get('RESULTS')
												: labels.get('RESULT')}
										</span>
									</>
								)}
						</small>

						<i
							className="ddc-icon ddc-icon-arrow-select"
							aria-hidden="true"
						/>
					</span>
				</button>

				<Options
					facetId={facetId}
					options={defaultOptions}
					renderBottom={renderBottom}
				/>
			</div>
			<div className="facet-select-border-right" />
			<div className="facet-select-border-left" />
		</div>
	);
};

FacetSelect.propTypes = {
	// Data
	facet: PropTypes.shape({
		values: PropTypes.arrayOf({ optionType }),
		id: PropTypes.string,
		allLabel: PropTypes.string,
		label: PropTypes.string
	}),

	// Attributes
	id: PropTypes.string,

	// Renderers
	renderBottom: PropTypes.func,

	// Events
	onChange: PropTypes.func,
	inputClass: PropTypes.string,
	onVisibleChange: PropTypes.func
};

export default FacetSelect;
