import React from 'react';
import logger from 'ws-scripts/modules/logger';
import { ErrorBoundary } from 'wsm-error-boundary';
import ErrorAlert from '../components/common/ErrorAlert';
import Component from '../components/Component';

const WidgetContainer = () => {
	return (
		<ErrorBoundary
			newRelicPageAction="WS FACET BROWSE ERROR BOUNDARY"
			errorMarkup={<ErrorAlert />}
			errorHandler={(error, errorInfo) => {
				const newError = new Error(
					`Encountered an error in ws-facet-browse.\n${error}`
				);
				newError.originalError = error;
				newError.originalStackTrace = errorInfo.componentStack;
				logger.error(`${newError}\n${newError.originalStackTrace}`);
			}}
		>
			<Component />
		</ErrorBoundary>
	);
};

WidgetContainer.propTypes = {};

export default WidgetContainer;
