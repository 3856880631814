import PropTypes from 'prop-types';

export const optionType = PropTypes.shape({
	label: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
});

export const valueType = PropTypes.oneOfType([
	PropTypes.string,
	PropTypes.number
]);
