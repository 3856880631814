import { trackEvent } from 'ddc-track-event';

function capitalizeFirstLetter(str) {
	return str.charAt(0).toUpperCase() + str.slice(1);
}

const dropdownTrackingType = ['compositeType', 'year', 'make', 'model'];
const rangeFormTrackingType = ['slider', 'input'];

/**
 * Create the dropdown tracking event based on facet id
 * @param {*} widgetName
 * @param {*} windowId
 * @param {*} facetId
 */
export const dropdownTracking = (widgetName, windowId, facetId) => {
	if (!dropdownTrackingType.includes(facetId)) {
		return;
	}

	const trackingType = facetId === 'compositeType' ? 'condition' : facetId;

	trackEvent(widgetName, windowId, {
		element: `${capitalizeFirstLetter(trackingType)} drop down menu`,
		result: `List of ${trackingType}s presented to user`
	});
};

/**
 * Create the range form tracking event based on facet id and type
 * @param {*} widgetName
 * @param {*} windowId
 * @param {*} facetId
 * @param {*} type
 */
export const rangeFormTracking = (widgetName, windowId, facetId, type) => {
	if (!rangeFormTrackingType.includes(type)) {
		return;
	}

	const elementCTA = type === 'input' ? 'Text input' : 'Range slider';

	trackEvent(`${widgetName}-modal`, windowId, {
		action: 'applied',
		element: `${facetId} selection`,
		elementCTA,
		result: 'Results updated with facet added'
	});
};

/**
 * Create the dropdown selected tracking event based on facet id and value
 * @param {*} widgetName
 * @param {*} windowId
 * @param {*} facetId
 * @param {*} facetValue
 */
export const dropdownSelectedTracking = (
	widgetName,
	windowId,
	facetId,
	facetValue
) => {
	trackEvent(widgetName, windowId, {
		action: 'applied',
		element: `${facetId} selection`,
		elementCTA: facetValue,
		result:
			facetValue === 'ALL'
				? 'Results updated with facet removed'
				: 'Results updated with facet added'
	});
};
