import { SET_HTTP_STATUS } from '../actions/types';

export const HTTP_STATUS = {
	DONE: 'done',
	REQUESTING: 'requesting',
	SUCCESS: 'success',
	FAIL: 'fail'
};
const initialState = {
	status: HTTP_STATUS.DONE
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case SET_HTTP_STATUS:
			return { ...state, ...payload };
		default:
			return state;
	}
};
