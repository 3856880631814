/**
 * This method takes a facetObj (which comes from the getQueryStringObj method) and returns formatted URL params.
 * @param facetObj
 * @returns {string}
 */
export const convertQuerryString = (facetObj) => {
	let queryString = '';

	switch (typeof facetObj) {
		case 'object':
			// eslint-disable-next-line no-constant-condition
			if (typeof facetObj) {
				Object.entries(facetObj).forEach(([name, values]) => {
					values.forEach((value) => {
						if (queryString) {
							queryString += '&';
						}
						queryString += `${encodeURIComponent(
							convertQuerryString(name)
						)}=${encodeURIComponent(value)}`;
					});
				});
				return `${queryString.length >= 1 ? '?' : ''}${queryString}`;
			} else {
				return '';
			}
		case 'string':
			return facetObj;
		case 'number':
			return String(facetObj);
		default:
			return '';
	}
};
