import { MAIN, MODAL } from '../constant';
import {
	setFacetDataMain,
	setFacetDataModal
} from '../actions/facetDataAction';

import { setFilterMain, setFilterModal } from '../actions/appliedFilterAction';

export const facetHelperMap = new Map();

facetHelperMap.set(MAIN, {
	facetFilterKey: 'appliedMainFilters',
	facetInstanceKey: 'facetInstanceId',
	setFacetDataAction: setFacetDataMain,
	setAppliedFilterAction: setFilterMain
});
facetHelperMap.set(MODAL, {
	facetFilterKey: 'appliedModalFilters',
	facetInstanceKey: 'facetInstanceIdAllFilters',
	setFacetDataAction: setFacetDataModal,
	setAppliedFilterAction: setFilterModal
});
