// This file is intended for global constants that are shared across
// the application. Use this file sparingly. For constants with a single
// usage, it can be better to include them in the module scope of the
// file they're used in.
export const noPrice = '0.0';
export const priceFacetIds = [
	'internetPrice',
	'msrp',
	'retailValue',
	'invoicePrice'
];

export const g = {
	EMBEDDED: 'embedded',
	INPUT: 'input',
	DISABLED: 'disabled',
	BUTTON: 'button',
	DESKTOP: 'DESKTOP',
	MOBILE: 'MOBILE',
	PAGE: 'page'
};
