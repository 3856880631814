import { SET_WIDGET_DATA } from '../actions/types';

const widgetData = (state = {}, { type, payload }) => {
	if (payload) {
		switch (type) {
			case SET_WIDGET_DATA:
				return { ...state, ...payload };
			default:
		}
	}
	return state;
};

export default widgetData;
