import React from 'react';
import PropTypes from 'prop-types';
import './Loading.scss';

const Loading = (props) => {
	const { openModal } = props;

	return (
		<div
			className={`${openModal ? 'modal-loading' : ''} loading-container`}
		>
			<div className="data-loading">
				<div className="bar bar-1" />
				<div className="bar bar-2" />
				<div className="bar bar-3" />
				<div className="bar bar-4" />
				<div className="bar bar-5" />
				<div className="bar bar-6" />
				<div className="bar bar-7" />
				<div className="bar bar-8" />
			</div>
		</div>
	);
};

Loading.propTypes = {
	openModal: PropTypes.bool
};

export default Loading;
