export const getRequestBody = ({
	requestData,
	prefs,
	inventoryParameters = {}
}) => {
	const { siteId, locale, deviceType } = requestData;
	const {
		'listing.config.id': listingConfigId,
		facetInstanceId = '',
		removeEmptyConstraints,
		removeEmptyFacets,
		suppressAllConditions,
		violateUsedCompliance,
		showFranchiseVehiclesOnly
	} = prefs;
	return {
		siteId,
		locale,
		device: deviceType,
		inventoryParameters,
		pageAlias: 'INDEX',
		preferences: {
			'listing.config.id': listingConfigId,
			facetInstanceId,
			removeEmptyConstraints,
			removeEmptyFacets,
			violateUsedCompliance,
			showFranchiseVehiclesOnly,
			suppressAllConditions: `${suppressAllConditions === 'true'}`
		}
	};
};
