import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { setClassNames } from 'ddc-classnames-js';
import { useLabels } from 'wsm-common-data';
import useFormatNumber from '../../hooks/useFormatNumber';
import { useSelectWithContext } from '../../hooks/useSelectWithContext';

const Option = ({ highlighted, selected, option, index }) => {
	const { getItemProps } = useSelectWithContext();
	const formatNumber = useFormatNumber();
	const labels = useLabels();

	const buttonProps = {
		value: option.value
	};

	const className = setClassNames([
		'option',
		'text',
		selected && 'is-selected',
		highlighted && 'is-highlighted'
	]);

	const classLabel = setClassNames([
		highlighted && 'text-primary text-decoration-underline'
	]);

	return (
		<li
			key={option.value}
			{...getItemProps({ option: option.value, index })}
		>
			<button type="button" className={className} {...buttonProps}>
				<span>
					<span className={classLabel}>{option.label}</span>&nbsp;
					{(option.count === 0 || option.count) && (
						<small>
							{`${formatNumber(option.count)} `}
							<span className="sr-only">
								{option.count > 1
									? labels.get('RESULTS')
									: labels.get('RESULT')}
							</span>
						</small>
					)}
				</span>
			</button>
		</li>
	);
};

Option.propTypes = {
	option: PropTypes.shape({
		label: PropTypes.string.isRequired,
		value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		disabled: PropTypes.bool,
		index: PropTypes.number,
		count: PropTypes.number
	}).isRequired,
	highlighted: PropTypes.bool.isRequired,
	selected: PropTypes.bool.isRequired,
	optionProps: PropTypes.shape({
		tabIndex: PropTypes.string.isRequired,
		onMouseDown: PropTypes.func.isRequired
	}).isRequired,
	index: PropTypes.number.isRequired
};

export default memo(Option);
