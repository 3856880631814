const filterFacetDisplay = (facets, numberOfDisplayFacets) => {
	const facetFilter = facets.filter((item) => item.type !== 'USER_RANGE');

	// return if numberOfDisplayFacets bigger than the facet number have left after filter
	if (
		facetFilter.length < numberOfDisplayFacets ||
		numberOfDisplayFacets < 1
	) {
		return facetFilter;
	}
	// slice facet number by numberOfDisplayFacets
	return facetFilter.slice(0, numberOfDisplayFacets);
};

export default filterFacetDisplay;
