import { useEffect, useState, Suspense } from 'react';
import PropTypes from 'prop-types';

const isBrowser = typeof window !== 'undefined';

const deferLoadPromise = async (maxWait) => {
	return Promise.race([
		new Promise((resolve) => {
			window.addEventListener('load', () => {
				resolve('pageLoad');
			});
		}),
		new Promise((resolve) => {
			setTimeout(() => {
				resolve('maxWait');
			}, maxWait);
		})
	]);
};

const isLazyComponent = (comp) => {
	return comp.type.$$typeof === Symbol.for('react.lazy');
};

const DeferredComponent = ({
	children,
	placeholder = null,
	maxWait = 5000
}) => {
	if (process.env.NODE_ENV === 'development') {
		if (![].concat(children).some(isLazyComponent)) {
			// if in dev ask to make sure the configuration is intentional. Sending non lazy components as children will
			// delay their rendering but not split the code from the initial bundle download this could still be useful
			// in cases where the component doesnt have a large impact on the bundle but does affect page load time.
			const childClasses = []
				.concat(children)
				.map((c) => c.type.displayName || c.type);
			// eslint-disable-next-line no-console
			console.warn(
				`No lazy components passed to DeferredComponent; Found: [${childClasses}]. Did you mean to wrap one of these in a React.lazy import? See: https://reactjs.org/docs/code-splitting.html#reactlazy for details`
			);
		}
	}

	const [ready, setReady] = useState(false);

	useEffect(() => {
		if (document.readyState === 'complete') {
			setReady(true);
		} else {
			deferLoadPromise(maxWait).then((_reason) => {
				setReady(true);
			});
		}
	}, [maxWait]);

	if (!isBrowser || !ready) {
		return placeholder;
	}

	return <Suspense fallback={placeholder}>{children}</Suspense>;
};

DeferredComponent.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.element,
		PropTypes.arrayOf(PropTypes.element)
	]).isRequired,
	placeholder: PropTypes.element,
	maxWait: PropTypes.number
};

DeferredComponent.displayName = 'DeferredComponent';

export default DeferredComponent;
