/* eslint-disable no-param-reassign */
import { SET_FACET_DATA_MAIN, SET_FACET_DATA_MODAL } from '../actions/types';
import addAnyFacetItem from '../utilities/addAnyFacetItem';

export const facetDataMiddleware =
	({ getState }) =>
	(next) =>
	(action) => {
		if (typeof action === 'object') {
			const { type, payload } = action;

			// Create Any facet selection for single select facets
			if ([SET_FACET_DATA_MAIN, SET_FACET_DATA_MODAL].includes(type)) {
				const { labels } = getState();

				payload?.facets?.forEach((facet) => {
					const { id: facetId } = facet;
					if (facetId === 'compositeType') {
						facet.label = labels.TYPE;
					}
					if (!facet.multiselect || type === SET_FACET_DATA_MAIN) {
						facet.values = addAnyFacetItem(labels, facet);
					}
				});
			}
		}

		next(action);
	};
