import React, { useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useLabels } from 'wsm-common-data';
import { ErrorBoundary } from 'wsm-error-boundary';
import { HTTP_STATUS } from '../../reducers/httpReducer';
import SearchButton from '../buttons/SearchButton';
import { convertFilter } from '../../utilities/convertFillters';
import Loading from '../common/Loading';
import { removeAllFiltersModal } from '../../actions/appliedFilterAction';
import {
	getHttp,
	getFacetDataModal,
	getFilerModal
} from '../../utilities/selector';
import DeferredComponent from '../../ws-scripts/components/DeferredComponent';
import '../style.scss';
import useUID from '../../hooks/useUID';

const FacetGroup = React.lazy(() =>
	import(
		/* webpackChunkName: "ModalBody" , webpackPrefetch: true */ '../FacetGroup'
	)
);

const FacetFilters = React.lazy(() =>
	import(
		/* webpackChunkName: "ModalBody" , webpackPrefetch: true */ './FacetFilters'
	)
);

const FilterModal = (props) => {
	const { openModal, isDesktop, setOpenModal, containerRef } = props;

	const modalFacetData = useSelector(getFacetDataModal);
	const { status } = useSelector(getHttp);
	const userSelectedFilter = useSelector(getFilerModal);
	const userFilters = convertFilter(userSelectedFilter);

	const labels = useLabels();
	const dispatch = useDispatch();
	const modalTitleId = useUID();

	const getFacetGroups = (data, filters) => {
		return data?.facets?.map((item, index) => {
			const facetSelections = filters.find(
				(filter) => filter.id === item.id
			);
			return (
				<ErrorBoundary newRelicPageAction="WS FACET BROWSE ERROR BOUNDARY">
					<FacetGroup
						index={`facet-group-${index}`}
						key={item.id}
						facet={item}
						isDesktop={isDesktop}
						selectedValues={
							facetSelections ? facetSelections.values : []
						}
					/>
				</ErrorBoundary>
			);
		});
	};

	const onClose = useCallback(() => {
		// remove overflow hidden for body
		const bodyContainer = document.body;
		bodyContainer.classList.remove('overflow-hidden');

		dispatch(removeAllFiltersModal({ compositeType: [] }));
		setOpenModal(false);
	}, [dispatch, setOpenModal]);

	return (
		<Modal
			show={openModal}
			onHide={onClose}
			backdrop="static"
			className={`${!isDesktop ? 'dock-full' : ''} ${
				status === HTTP_STATUS.REQUESTING ? 'data-loading-modal' : ''
			}`}
			id="ws-facet-browse-modal"
			aria-labelledby={modalTitleId}
			backdropClassName="ws-facet-browse-modal-backdrop"
			container={containerRef}
		>
			<Loading openModal={openModal} />
			<div
				className={`${
					isDesktop ? 'px-10 pb-10 pt-6' : 'modal-body'
				} facet-filter-modal`}
			>
				<div className="d-flex mb-3 mt-2">
					<h4
						className="ddc-font-size-medium modal-title text-body"
						id={modalTitleId}
					>
						{labels.get('ALL_FILTERS')}
					</h4>

					<button
						className="text-link font-weight-bold ml-auto"
						type="button"
						onClick={onClose}
					>
						{labels.get('CANCEL')}
					</button>
				</div>
				<DeferredComponent
					placeholder={<div>Loading....</div>}
					maxWait={0}
				>
					<FacetFilters facetData={modalFacetData} />
					<hr
						className={`${
							isDesktop ? 'mb-5 mt-5 mx-n10' : 'mb-0'
						} modal-divider`}
					/>
					<div className="pb-4" id="new-facet-browse">
						{getFacetGroups(modalFacetData, userFilters)}
					</div>

					<Modal.Footer className="border-0 p-0">
						<SearchButton
							className="w-100 btn btn-primary"
							isInModal
							totalMatchFilter={modalFacetData?.totalCount}
							facetFilters={userSelectedFilter}
						/>
					</Modal.Footer>
				</DeferredComponent>
			</div>
		</Modal>
	);
};
FilterModal.propTypes = {
	openModal: PropTypes.bool.isRequired,
	isDesktop: PropTypes.bool.isRequired,
	setOpenModal: PropTypes.func.isRequired,
	containerRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.instanceOf(Element) })
	])
};
export default FilterModal;
