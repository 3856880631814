import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ name, className }) => {
	return (
		<i
			className={`ddc-icon ddc-icon-${name} ${className || ''}`}
			aria-hidden="true"
		/>
	);
};

Icon.propTypes = {
	name: PropTypes.string.isRequired,
	className: PropTypes.string
};

export default Icon;
