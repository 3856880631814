import { hysteriFetch, wrappers } from 'hysterics';
import logger from 'ws-scripts/modules/logger';
import { getRequestBody } from './getRequestBody';

const inventoryClientService = hysteriFetch('inventoryClientService');

export default async function fetchFacetsData({
	requestData,
	prefs,
	inventoryParameters,
	flags
}) {
	const facetReqBody = getRequestBody({
		requestData,
		prefs,
		inventoryParameters
	});
	let response;
	try {
		response = await inventoryClientService(
			'/api/widget/ws-facet-browse/getFacets',
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({ facetReqBody, flags })
			},
			{
				[wrappers.timeout.contextKey]: {
					timeout: 2000
				}
			}
		);
	} catch (err) {
		const errorText = `ws-facet-browse: Error attempting to retrieve facet data from. ${err.message}`;
		const error = new Error(errorText);
		logger.error(error);
		response = { error: errorText };
	}

	return response;
}
