/**
 * Convert input string to snake case format
 * @param {*} inputString Like 'bodyStyle','compositeType'
 * @returns {string} string like 'body_style, 'composite_type'
 */
export default function convertToSnakeCase(inputString) {
	return (inputString || '').replace(
		/[A-Z]/g,
		(letter) => `_${letter.toLowerCase()}`
	);
}
