import { facetHelperMap } from '../utilities/facetInstanceType';
import fetchFacetsData from '../utilities/clientFetchFacetDataService';
import { httpFailed, httpRequesting, httpSuccess } from './httpActions';

export const createFetchAction = (payload) => {
	return async (dispatch, getState) => {
		const { action, prefs, triggerSource } = payload;

		if (action) dispatch(action);

		const state = getState();
		const {
			facetFilterKey,
			setFacetDataAction,
			facetInstanceKey,
			setAppliedFilterAction
		} = facetHelperMap.get(triggerSource);

		const facetInstanceId = prefs[facetInstanceKey];

		const inventoryParameters = state[facetFilterKey];

		dispatch(httpRequesting());

		const response = await fetchFacetsData({
			...payload,
			prefs: { ...prefs, facetInstanceId },
			inventoryParameters
		});

		if (response.error) {
			dispatch(httpFailed());
			return;
		}

		const mergedFacetFilters = {};

		response.filters?.forEach(({ id, values } = {}) => {
			mergedFacetFilters[id] = [
				...new Set([
					...(inventoryParameters[id] || []),
					...(values?.map((item) => item.value) || [])
				])
			];
		});

		dispatch(setAppliedFilterAction(mergedFacetFilters));
		dispatch(setFacetDataAction(response));
		dispatch(httpSuccess());
	};
};
