import React from 'react';
import { useSelect } from 'downshift';
import PropTypes from 'prop-types';
import { optionType } from '../components/FacetSelect/type';

const SelectContext = React.createContext();

function SelectProvider({ children, items, useSelectProps }) {
	/**
	 * Read more for useSelectProps:
	 *  - https://github.com/downshift-js/downshift/tree/master/src/hooks/useSelect#basic-props
	 *  - https://github.com/downshift-js/downshift/blob/7200d446d630fd72a2b40b858d8809f8355f5421/src/hooks/useSelect/utils.ts#L38
	 */

	const initialSelectedItem =
		items.find((item) => item.selected === true) || items[0];
	const initialHighlightedIndex = -1;

	const value = useSelect({
		items,
		initialSelectedItem,
		initialHighlightedIndex,
		...useSelectProps
	});
	return (
		<SelectContext.Provider value={value}>
			{children}
		</SelectContext.Provider>
	);
}

function useSelectWithContext() {
	const context = React.useContext(SelectContext);
	if (context === undefined) {
		throw new Error(
			'useSelectWithContext must be used within a SelectProvider'
		);
	}
	return context;
}

SelectProvider.propTypes = {
	children: PropTypes.node.isRequired,
	items: PropTypes.arrayOf({ optionType }),
	useSelectProps: PropTypes.shape({
		itemToString: PropTypes.func,
		onSelectedItemChange: PropTypes.func,
		getA11ySelectionMessage: PropTypes.func,
		getA11yStatusMessage: PropTypes.func
	})
};

export { SelectProvider, useSelectWithContext };
