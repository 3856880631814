import { SET_FACET_DATA_MAIN } from '../actions/types';

const initialState = {};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case SET_FACET_DATA_MAIN:
			return { ...state, ...payload };

		default:
			return state;
	}
};
