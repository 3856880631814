import { SET_FACET_DATA_MAIN, SET_FACET_DATA_MODAL } from './types';

export const setFacetDataMain = (payload) => {
	return {
		type: SET_FACET_DATA_MAIN,
		payload
	};
};

export const setFacetDataModal = (payload) => {
	return {
		type: SET_FACET_DATA_MODAL,
		payload
	};
};
