// Widget data actions
export const SET_WIDGET_DATA = '@widgetData/setData';

// http actions
export const SET_HTTP_STATUS = '@http/setStatus';
// mainFacetData actions
export const SET_FACET_DATA_MAIN = '@mainFacetData/setData';

// modalFacetData actions
export const SET_FACET_DATA_MODAL = '@modalFacetData/setData';

// appliedFilters actions
export const SET_APPLIED_FILTER_MAIN = '@appliedMainFilters/setData';
export const SET_APPLIED_FILTER_MODAL = '@appliedModalFilters/setData';
export const REMOVE_FACET_FILTERS_MODAL = '@appliedModalFilters/remove-all';

// a11yStatus actions
export const SET_A11Y_STATUS = '@a11yStatus/setStatus';
