import React from 'react';
import { useLabels } from 'wsm-common-data';

const ErrorAlert = () => {
	const labels = useLabels();
	return (
		<div className="alert alert-info">
			<i className="ddc-icon ddc-icon-alert" aria-hidden="true" />
			<div className="alert-content">{labels.get('NETWORK_ERROR')}</div>
		</div>
	);
};

export default ErrorAlert;
