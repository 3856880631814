import widgetData from './widgetDataReducer';
import http from './httpReducer';
import appliedMainFilters from './appliedMainFiltersReducer';
import appliedModalFilters from './appliedModalFiltersReducer';
import mainFacetData from './mainFacetDataReducer';
import modalFacetData from './modalFacetDataReducer';
import a11yMessage from './a11yMessageReducer';

const root = {
	widgetData,
	http,
	appliedMainFilters,
	appliedModalFilters,
	mainFacetData,
	modalFacetData,
	a11yMessage
};

export default root;
