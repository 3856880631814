import getAnyLabel from './getAnyLabel';
import { ALL } from '../constant';

/**
 *  Helper used to add ANY option
 * @param {*} values list option
 * @param {*} labels
 * @param {*} facetId
 * @param {*} allLabel
 * @returns
 */
export default function addAnyFacetItem(labels, facet) {
	const { values, id: facetId, allLabel, label } = facet;
	if (!values.find((item) => item.value === ALL)) {
		if (allLabel) {
			return [
				{
					label: getAnyLabel(labels, facetId, label),
					value: ALL
				},
				...values
			];
		}
	}
	return values;
}
