import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
	useLabels,
	usePrefs,
	useSitemap,
	useRequestData
} from 'wsm-common-data';
import { setClassNames } from 'ddc-classnames-js';
import { trackEvent } from 'ddc-track-event';
import useFormatNumber from '../../hooks/useFormatNumber';
import { convertQuerryString } from '../../utilities/url-params';

const SearchButton = (props) => {
	const { widgetName, windowId } = useRequestData();
	const { totalMatchFilter = 0, className, isInModal, facetFilters } = props;
	const allowAccesLocation = useRef(false);
	const prefs = usePrefs();
	const {
		buttonSizeSubmit,
		buttonStyleSubmit,
		buttonSubmitClasses,
		buttonTextSubmit,
		showCountOnSubmitButton,
		defaultRange,
		linkToConditionPages,
		defaultListingAlias
	} = prefs;
	const labels = useLabels();
	const sitemap = useSitemap();
	const formatNumber = useFormatNumber();
	const formattedTotal = formatNumber(totalMatchFilter);

	// eslint-disable-next-line no-return-assign
	const onLocationSucess = () => (allowAccesLocation.current = true);

	useEffect(() => {
		// request HTML5 geolocation info
		if (navigator?.geolocation) {
			navigator?.geolocation?.getCurrentPosition(onLocationSucess);
		}
	}, []);

	const getQueryString = () => {
		// Add defaultRange to filter object
		const filterData =
			defaultRange && allowAccesLocation.current
				? { ...facetFilters, defaultRange: [defaultRange] }
				: facetFilters;
		return convertQuerryString(filterData);
	};

	const getSubmitUrl = () => {
		const { compositeType: [type] = [] } = facetFilters;

		let alias = defaultListingAlias;

		if (type && linkToConditionPages === 'true') {
			alias = prefs[`${type}ListingAlias`];
		}

		return sitemap.get(alias);
	};

	const handleSearch = () => {
		trackEvent(widgetName, windowId, {
			element: 'Facet search button',
			result: 'User navigates to search results page.'
		});

		const url = getSubmitUrl();
		const queryString = getQueryString();
		window?.DDC.redirect(`${url}${queryString}`);
	};

	const wrapperClass = [className];
	let searchLabel =
		totalMatchFilter > 1
			? labels.get('SHOW_X_MATCHES').replace('{count}', formattedTotal)
			: labels.get('SHOW_X_MATCH').replace('{count}', formattedTotal);

	// Preferences should not effect button in Modal
	if (!isInModal) {
		// Style config
		if (buttonStyleSubmit) {
			wrapperClass.push(`btn-${buttonStyleSubmit}`);
		}

		// Size config
		if (buttonSizeSubmit && buttonSizeSubmit !== 'default') {
			let sizeClass;

			switch (buttonSizeSubmit) {
				case 'xlarge': {
					sizeClass = 'btn-xl';
					break;
				}
				case 'large': {
					sizeClass = 'btn-lg';
					break;
				}
				case 'small': {
					sizeClass = 'btn-sm';
					break;
				}
				case 'xsmall': {
					sizeClass = 'btn-xs';
					break;
				}
				default: {
					sizeClass = `btn-${buttonSizeSubmit}`;
				}
			}

			wrapperClass.push(sizeClass);
		}

		// ClassName config
		if (buttonSubmitClasses) {
			wrapperClass.push(buttonSubmitClasses);
		}

		// Labels config
		const viewXVehicles =
			totalMatchFilter > 1
				? labels
						.get('SHOW_X_MATCHES')
						.replace('{count}', formattedTotal)
				: labels.get('SHOW_X_MATCH').replace('{count}', formattedTotal);

		searchLabel =
			showCountOnSubmitButton === 'true'
				? viewXVehicles
				: labels.get(buttonTextSubmit);
	}

	return (
		<button
			className={setClassNames(wrapperClass)}
			type="button"
			onClick={handleSearch}
			aria-label={searchLabel}
		>
			{searchLabel}
		</button>
	);
};

SearchButton.propTypes = {
	totalMatchFilter: PropTypes.number,
	className: PropTypes.string,
	isInModal: PropTypes.bool,
	facetFilters: PropTypes.shape({
		compositeType: PropTypes.arrayOf(PropTypes.string)
	})
};
export default SearchButton;
