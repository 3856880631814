export const FACET_INSTANCE_ID = 'listing';
export const ALL = 'ALL';
export const MAIN = 'MAIN';
export const MODAL = 'MODAL';
export const layout = {
	TWO_COLUMN: 'two-column',
	ROW: 'row',
	DEFAULT: 'default',
	INLINE: 'inline'
};
