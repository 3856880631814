import { hot } from 'react-hot-loader/root';
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import WidgetHeading from './WidgetHeading';
import Collapse from './Collapse';
// NOTE: The eslint-disable-line is needed because this file won't lint
// when it's in the ws-scripts repo because the file only exists in the
// widget. It's safe because the entire application won't work if the
// file wasn't really resolved. It would be really apparent quickly.
import Widget from '../../../containers/Widget'; // eslint-disable-line import/no-unresolved

const getWidgetClasses = (widgetClasses, widgetName, toggleCollapse) => {
	const classes = ['ddc-content', widgetName];
	const wrapperClasses = '';

	if (widgetClasses) {
		classes.push(widgetClasses);
	}

	if (toggleCollapse) {
		classes.push('collapse-container');
	}

	if (wrapperClasses) {
		classes.push(wrapperClasses);
	}

	return classes;
};

const getIconClasses = (prefs, showIcon, toggleCollapse) => {
	const iconClasses = [];
	const {
		widgetHeading,
		headingIconClasses,
		headingIconSize,
		headingIconStyle
	} = prefs;

	if (widgetHeading && showIcon) {
		iconClasses.push('ddc-icon');

		if (!toggleCollapse) {
			iconClasses.push(`ddc-icon-${headingIconStyle}`);
		}
		if (headingIconSize) {
			iconClasses.push(`ddc-icon-size-${headingIconSize}`);
		}
		if (headingIconClasses) {
			iconClasses.push(headingIconClasses);
		}
	}

	return iconClasses;
};

const getHeadingClasses = (prefs, deviceType, toggleCollapse) => {
	const classes = [];
	const { headingSize, headingStyles, headingClasses } = prefs;

	classes.push('widget-heading');

	if (deviceType === 'MOBILE') {
		classes.push('ddc-heading');
	}
	if (headingClasses) {
		classes.push(headingClasses);
	}
	if (headingSize) {
		classes.push(headingSize);
	}
	if (headingStyles) {
		classes.push(`ddc-heading-${headingStyles}`);
	}
	if (toggleCollapse) {
		classes.push('collapse-heading');
	}

	return classes;
};

const WidgetWrapper = ({ outputWrapperDiv, store, widgetId, widgetName }) => {
	const state = store.getState();

	const prefs = state.prefs || {};

	const toggleCollapse =
		(prefs.widgetHeading && prefs.toggleCollapse === 'true') || false;

	const showIcon = prefs.headingIconStyle ? true : toggleCollapse;

	const isBrowser = typeof window !== 'undefined';

	if (process.env.NODE_ENV === 'development' && isBrowser) {
		(async () => {
			const axe = require('@axe-core/react');
			const widget = document.querySelectorAll(
				`[data-widget-id="${widgetId}"]`
			);
			await axe(React, ReactDOM, 1000, undefined, widget);
		})();
	}

	const wrapping = (
		<Provider store={store}>
			<WidgetHeading
				HeadingTag={prefs.headingTag}
				headingText={prefs.widgetHeading}
				widgetId={widgetId}
				toggleCollapse={toggleCollapse}
				showIcon={showIcon}
				headingClasses={getHeadingClasses(
					prefs,
					state.requestData.deviceType,
					toggleCollapse
				)}
				iconClasses={getIconClasses(prefs, showIcon, toggleCollapse)}
			/>
			<Collapse id={widgetId} toggleCollapse={toggleCollapse}>
				<Widget />
			</Collapse>
		</Provider>
	);

	if (outputWrapperDiv) {
		if (!widgetName) {
			throw new Error(
				'widgetName is required if outputWrapperDiv is true.'
			);
		}

		return (
			<div
				id={`${widgetId}-app-root`}
				className={getWidgetClasses(
					prefs.widgetClasses,
					widgetName,
					toggleCollapse
				).join(' ')}
				data-widget-name={widgetName}
				data-widget-id={widgetId}
			>
				{wrapping}
			</div>
		);
	}

	return wrapping;
};

WidgetWrapper.propTypes = {
	outputWrapperDiv: PropTypes.bool.isRequired,
	store: PropTypes.shape({
		getState: PropTypes.func.isRequired
	}).isRequired,
	widgetId: PropTypes.string.isRequired,
	widgetName: PropTypes.string
};

export default hot(WidgetWrapper);
